import {ref} from 'vue';
import axios from 'axios';
const getUserInfo=()=>{
    const user=ref('');
    const load=async()=>{
        try{
            await axios.get('/api/user').then((res)=>[
                user.value=res.data.users
            ]);
        }catch(err){
            console.log(err);
        }
    }
    return {user,load}
}
export default getUserInfo;