import axios from "axios";
import { ref } from "vue";

const getSkill = () => {
    const skills = ref(null);
    const loadSkill = async () => {
        await axios.get("/api/skills").then((res) => {
            skills.value = res.data.data;
        });
    };
    return { skills, loadSkill };
};

export default getSkill;
