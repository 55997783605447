<template>
  <div class="tokyo_tm_about" v-if="userDetails && aboutDetails">
    <div class="about_image">
      <img :src="`${$store.state.baseURL}${aboutDetails.image}`" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name">{{aboutDetails.title}}</h3>
      <div class="description_inner">
        <div class="left">
          <p v-html="aboutDetails.description"></p>
          <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Read More</button>
          </div>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>Birthday:</span>{{userDetails.dob}}</p>
            </li>
            <li>
              <p><span>Age:</span>{{getAge}}</p>
            </li>
            <li>
              <p><span>Address:</span>{{userDetails.address}}</p>
            </li>
            <li>
              <p>
                <span>Email:</span
                ><a :href="`mailto:${userDetails.email}`">{{userDetails.email}}</a>
              </p>
            </li>
            <li>
              <p>
                <span>Phone:</span
                ><a :href="`tel:${userDetails.contact}`">{{userDetails.contact}}</a>
              </p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Frontend Skills</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="tokyo_progress">
                    <div class="progress_inner" v-for="skill in skillDetails" :key="skill.id">
                      <template v-if="skill.type=='front'">
                        <span>
                          <span class="label">{{skill.title}}</span>
                          <span class="number">{{skill.knowledge}}%</span>
                        </span>
                        <div class="background">
                          <div class="bar">
                            <div class="bar_in" :style="{ width: `${skill.knowledge}%` }"></div>
                          </div>
                        </div>
                      </template>  
                    </div>
                  </div>
                  <!-- End .tokyo_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Backend Skills</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="tokyo_progress" style="margin-top:15px">
                    <div class="progress_inner" v-for="skill in skillDetails" :key="skill.id">
                      <template v-if="skill.type=='back'">
                        <span
                          ><span class="label">{{skill.title}}</span
                          ><span class="number">{{skill.knowledge}}%</span></span
                        >
                        <div class="background">
                          <div class="bar">
                            <div class="bar_in" :style="{ width: `${skill.knowledge}%` }"></div>
                          </div>
                        </div>
                      </template>  
                    </div>
                  </div>
                  <!-- End .tokyo_progress -->
                </div>
              </div>
              <!-- End .partner -->
              <div class="qualification" style="margin-bottom:40px">
               <div class="about_title">
                  <h3>Qualifications</h3>
                  <ul>
                    <li v-for="qualification in qualificationDetails" :key="qualification.id">                    
                      <div>
                        <div class="custom-flex">
                          <h3 class="about-title">{{qualification.institution}}</h3>
                          <span class="info">
                            <p>{{qualification.start_date}}</p>
                            <p v-if="qualification.end_date">{{qualification.end_date}}</p>
                          </span>  
                        </div>  
                        <p style="color:grey">{{qualification.type}}, {{qualification.degree}}</p>
                      </div>
                    </li>  
                  </ul>  
                </div>
              </div>

              <div class="work_experience" style="margin-bottom:40px">
               <div class="about_title">
                  <h3>Work Experience</h3>
                  <ul>
                     <li v-for="experience in experienceDetails" :key="experience.id">                    
                      <div>
                        <div class="custom-flex">
                          <h3 class="about-title">{{experience.organization}}</h3>
                          <span class="info">
                            <p>{{getJoinDate(experience.join_date)}}</p>
                            <p v-if="experience.end_date">{{getEndDate(experience.end_date)}}</p>
                          </span>  
                        </div>  
                        <p style="color:grey">{{experience.role}}</p>
                      </div>
                    </li>
                  </ul>  
                </div>
              </div>
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</template>

<script>
import moment from 'moment'
import getUserInfo from '../composables/getUserInfo';
import getPage from '../composables/getPage';
import getQualification from '../composables/getQualification';
import getExperience from '../composables/getExperience';
import getSkill from '../composables/getSkill';
export default {
  data() {
    return {
      isActive: false,
      isVisible: false,
      userDetails:"",
      aboutDetails:"",
      qualificationDetails:"",
      experienceDetails:'',
      skillDetails:''
    };
  },
  methods: {
    showModal: async function() {
      const {qualifications,loadQualification}=getQualification();
      const {experiences,loadExperience}=getExperience();
      const {skills,loadSkill}=getSkill();
      await loadQualification();
      await loadExperience();
      await loadSkill();
      this.qualificationDetails=qualifications;
      this.experienceDetails=experiences;
      this.skillDetails=skills;
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
    
    getJoinDate(date){
      return moment(date).format("MMM YYYY");  
    },
    getEndDate(date){
      return moment(date).format("MMM YYYY");  
    }
  },

  async mounted(){
    const {user,load}=getUserInfo();
    const {detail,loadPage}=getPage();
    await load(); // for user details
    await loadPage("about"); // for about page like title,description
    this.userDetails=user;
    this.aboutDetails=detail;
  },

  computed:{
    getAge(){
      return moment().diff(this.userDetails.dob, 'years');
    },
  }


};
</script>

<style lang="scss">
  .custom-flex{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .info{
    color:grey; 
    display:flex;
    column-gap:0.75rem;   
  }

  .read-more-title{
    font-weight:bold; 
    color:black
  }

  @media only screen and (max-width: 600px) {
    .info {
      display: none;
    }
  }
</style>