import {ref} from 'vue';
import axios from 'axios';
const getPage=()=>{
    const detail=ref('');
    const loadPage=async(type)=>{
        try{
            await axios.get('/api/page',{
                params:{
                    type:type
                }
            }).then((res)=>[
                detail.value=res.data.data
            ]);
        }catch(err){
            console.log(err);
        }
    }
    return {detail,loadPage}
}
export default getPage;