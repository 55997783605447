import axios from "axios";
import { ref } from "vue";

const getExperience = () => {
    const experiences = ref(null);
    const loadExperience = async () => {
        await axios.get("/api/experiences").then((res) => {
            experiences.value = res.data.data;
        });
    };
    return { experiences, loadExperience };
};

export default getExperience;
