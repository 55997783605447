import axios from "axios";
import { ref } from "vue";

const getQualification = () => {
    const qualifications = ref(null);
    const loadQualification = async () => {
        await axios.get("/api/qualifications").then((res) => {
            qualifications.value = res.data.data;
        });
    };
    return { qualifications, loadQualification };
};

export default getQualification;
